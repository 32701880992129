export const useAsyncQuery = (key, queryOpts, opts = {}) => {
  const { $apolloClient } = useNuxtApp()

  const reqHeaders = useRequestHeaders()
  const headers = {}

  if (import.meta.server) {
    const visitorIP = reqHeaders?.["x-forwarded-for"] ?? null
    if (visitorIP) headers["Shopify-Storefront-Buyer-IP"] = visitorIP
  }

  return useAsyncData(key, async () => {
    const { data } = await $apolloClient.query({
      ...queryOpts,
      fetchPolicy: "no-cache",
      context: {
        headers,
      },
    })
    return data
  }, { ...opts })
}
